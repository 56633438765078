import React from 'react';
import { RichText } from 'prismic-reactjs';
import DataTreatmentContent from '@/containers/DataTreatment/DataTreatmentContent';

import useHtmlSerializer from '@/prismic/useHtmlSerializer';

import { LegalTemplateQuery } from '../../../graphql-types';

interface Props {
  data: LegalTemplateQuery;
  type?: string;
}

const DataTreatmentContainer = ({ data }: Props): JSX.Element => {
  const htmlSerializer = useHtmlSerializer();
  const { data_processing_page } = data?.prismicLegalPages?.data || {};

  const DataTreatmentProps = {
    dataTreatment: (
      <RichText
        render={data_processing_page?.raw}
        htmlSerializer={htmlSerializer}
      />
    ),
  };
  return (
    <>
      <DataTreatmentContent {...DataTreatmentProps} />
    </>
  );
};

export default DataTreatmentContainer;
