import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';

import mq from '@/styles/mq';

interface Props {
  dataTreatment: React.ReactNode;
}

const DataTreatmentContentRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(10)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(20)};
    padding-left: ${({ theme }) => theme.spacing(0)};
    padding-right: ${({ theme }) => theme.spacing(0)};
  }
`;

const ProductHeader = ({ dataTreatment }: Props): JSX.Element => {
  return (
    <DataTreatmentContentRoot maxWidth="lg">
      {dataTreatment}
    </DataTreatmentContentRoot>
  );
};

export const query = graphql`
  fragment DataTreatment on PrismicLegalPagesDataType {
    data_processing_page {
      raw
    }
  }
`;

export default ProductHeader;
